import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { fetchComponentsSeparationProfiles } from "../../../utils/queries/production/profiles/separation";
import icons from "../../../constants/icons";
import { Modal } from "../../Modal";
import Separations from "./Separations";
import { CardHeader } from "../../Header";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewSeparation extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    donation: {},
    isLoading: true,
    available: availableOptions[0],
    profiles: [],
    bagStatuses: [],
    volume: "",
    donationNumber: "",
    donations: [],
  };

  getDonation = async (searchDonation) => {
    try {
      if (this.state.donationNumber === "") {
        let { error } = this.state;

        error.donationNumber =
          language[this.props.defaultLanguage].donation_number_required;

        this.setState({ error });

        return;
      }
      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
      });

      let { category, donations } = this.state;

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        if (category === "bulkDonation") {
          donations.push(donation);
          donation = {};
        }
      }

      this.setState(
        {
          donation,
          searchDonation: false,
          donations,
        },
        () => {
          this.getComponentProfiles(true);
        }
      );
    } catch (error) {
      this.setState({ searchDonation: false });
    }
  };

  getComponentProfiles = async (isFetchingProfile, code) => {
    try {
      this.setState({
        isFetchingProfile,
      });

      const data = await fetchComponentsSeparationProfiles(
        this.props.defaultLanguage,
        {
          type: "separation",
          inputType: "dropdown",
          code,
        }
      );

      this.setState({
        isFetchingProfile: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingProfile: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];
    delete error["errorMessage"];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  handleChangeComponent = (idex, field, e) => {
    let { profile = {} } = this.state;
    let { components = [] } = profile;

    components[idex][field] = e.target ? e?.target?.value : e;
    this.setState({ profile });
  };

  validateForm() {
    let { profile, error } = this.state;

    if (!profile) {
      error.profile =
        language[this.props.defaultLanguage].component_profile_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      const { donation } = this.state;
      this.setState({
        isSubmitting: true,
      });
      //   if (category === "bulkDonation") {
      //     for (let el of donations) {
      //       await this.handleSeparationData(el);
      //     }
      //   } else {

      //   }

      //   this.setState({
      //     isSubmitting: false,
      //     profile: null,
      //     donationNumber: "",
      //   });

      await this.handleSeparationData(donation);
    }
  };

  handleSeparationData = async (donation) => {
    let { _id } = this.props;
    let { profile, available } = this.state,
      url = `${API_URL}/bloodSeparation`,
      method = "POST",
      user = await getStorage();

    this.setState({ isSubmitting: true });

    try {
      let requestedBody = {
        donation: donation._id,
        components: profile?.components,
        available: available.value,
        profile: profile._id,
        addedBy: user.id,
        productType: "ComponentSeparation",
      };

      if (method === "PUT") {
        requestedBody.modifiedBy = user.id;
      } else {
        requestedBody.addedBy = user.id;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;

        delete requestedBody.addedBy;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      await axios(options);

      this.setState({
        isSubmitting: false,
        profile: null,
        component: null,
        donationNumber: "",
        isRefreshing: true,
      });

      toastMessage(
        "success",
        `${
          language[this.props.defaultLanguage][
            method === "PUT" ? "update_data_success" : "add_data_success"
          ]
        }`
      );

      this.setState({ isSubmitting: true });
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  handleCheckBox(type) {
    this.setState({
      [type]: !this.state[type],
    });
  }

  onReset = () => {
    this.setState({
      donation: {},
      profile: null,
      component: null,
      donationNumber: "",
    });
  };

  handleCheck(field, value) {
    let { error } = this.state;
    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  renderDonationTableHeader = () => {
    const headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donationNumber",
      },
      {
        title: "",
        key: "multiple-actions",
        actions: [
          {
            icon: icons.delete,
            className: "btn-danger",
            onPress: (item) => this.handleDeleteDonation(item),
          },
        ],
      },
    ];
    return headers;
  };

  handleDeleteDonation(item) {
    this.setState((prevState) => ({
      donations: prevState.donations.filter(
        (donation) => donation._id !== item._id
      ),
    }));
  }

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <div className="card-body row">
          <form>
            <Input
              placeholder={language[this.props.defaultLanguage].search + "..."}
              label={language[this.props.defaultLanguage].donation_number}
              required
              value={this.state.donationNumber}
              error={this.state.error.donationNumber}
              onChange={(e) => this.onChangeText("donationNumber", e)}
            />
            <Button
              text={language[this.props.defaultLanguage].search}
              isSubmitting={this.state.searchDonation}
              onPress={(e) => {
                e.preventDefault();
                this.getDonation(true);
              }}
            />
          </form>

          {this.state.donation._id && this.state.donationNumber !== "" && (
            <>
              <Select
                loadOptions={(v) => this.getComponentProfiles(true, v)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].component_profiles}
                required
                value={this.state.profile}
                onChange={(e) => this.onChangeText("profile", e)}
                error={this.state.error.profile}
                isLoading={this.state.isFetchingProfile}
              />

              {this.state?.profile?.components?.map((component, index) => {
                return (
                  <div key={index} className="col-md-12">
                    <div className="col-md-6">
                      <Input
                        label={
                          language[this.props.defaultLanguage].component_name
                        }
                        required
                        value={component.code + " - " + component.name}
                        onChange={(e) =>
                          this.onChangeText(
                            `${component.code}_name_${index}`,
                            e
                          )
                        }
                        error={
                          this.state.error[`${component.code}_name_${index}`]
                        }
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={language[this.props.defaultLanguage].volume}
                        required
                        value={component.volume}
                        type="number"
                        onChange={(e) =>
                          this.handleChangeComponent(index, "volume", e)
                        }
                        min={component.volume}
                        max={component.maxVolume}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
          <div className="col-md-12">
            {this.state.donation._id && this.state.donationNumber !== "" ? (
              <div className="d-flex gap-2">
                <Button
                  text={language[this.props.defaultLanguage].reset}
                  onPress={(e) => {
                    e.preventDefault();
                    this.onReset();
                  }}
                  className="btn-default"
                />
                <Button
                  text={language[this.props.defaultLanguage].submit}
                  onPress={(e) => {
                    e.preventDefault();
                    this.onSubmit();
                  }}
                  isSubmitting={this.state.isSubmitting}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {this.state?.donation?._id && (
          <div className="card" style={{ marginTop: "1rem" }}>
            <CardHeader
              title={language[this.props.defaultLanguage].component_separation}
            />
            <div className="card-body">
              <Separations
                donationId={this.state?.donation?._id}
                isRefreshing={this.state.isRefreshing}
                type="separation"
                componentProfile={this.state?.componentProfile?.value}
                component={this.state?.component?.value}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSeparation);
