import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Modal } from "../../Modal";
import { getStorage } from "../../../utils/storage";
import { Input, Select } from "../../Input";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import toastMessage from "../../../utils/toastMessage";
import { Button } from "../../Button";
import { fetchAllComponents } from "../../../utils/queries/production/components";
import PrintableLabel from "../ComponentLabeling/printableLabel";
import axios from "axios";
import { fetchComponentSeparationData } from "../../../utils/queries/production/componentSeparationQuery";
import { fetchComponentTransformationData } from "../../../utils/queries/production/componentTransformationQuery";
import { Loading } from "../../Loader";

class ComponentLabeling extends React.Component {
  state = {
    isLoading: false,
    donation: {},
    error: {},
    user: {},
    components: [],
    donationNumber: "",
    productInfo: {},
    isFetchingProduct: true,
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  onChangeText(field, e) {
    let { error, donation } = this.state;

    delete error[field];

    const value = e.target ? e?.target?.value : e;

    this.setState({
      [field]: value,
      error,
    });

    // if (donation?._id && field === "component") {
    //   this.getProductInfo(donation._id, value.value);
    // }
  }

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({ [modal]: true, modalTitle, selectedData });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  validateForm() {
    let { donationNumber, component, error } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (!component) {
      error.component = language[this.props.defaultLanguage].component_required;
    }

    this.setState({ error });
  }

  getDonation = async () => {
    try {
      await this.validateForm();

      if (Object.keys(this.state.error).length > 0) return;

      let { error, donationNumber, component } = this.state;

      if (donationNumber === "") {
        error.donationNumber =
          language[this.props.defaultLanguage].donation_number_required;

        this.setState({ error });

        return;
      }

      this.setState({
        isSubmitting: true,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
      });

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        this.setState(
          {
            donation,
          },
          () => {
            this.onGenerateLabel(donation);
          }
        );
      } else {
        this.setState({ isSubmitting: false });
        toastMessage(
          "error",
          language[this.props.defaultLanguage].donation_not_found
        );
      }
    } catch (error) {
      this.setState({ searchDonation: false });
      toastMessage("error", "Donation not found!");
    }
  };

  getComponents = async (isFetchingComponents, code) => {
    try {
      this.setState({
        isFetchingComponents,
      });

      const data = await fetchAllComponents(this.props.defaultLanguage, {
        inputType: "dropdown",
        code: code ? code : undefined,
      });

      this.setState({
        isFetchingComponents: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingComponents: false });
    }
  };

  getProductInfo = async (donation, componentCode) => {
    try {
      this.setState({
        isFetchingComponents: true,
      });
      const separationInfo = await fetchComponentSeparationData(
        this.props.defaultLanguage,
        {
          inputType: "dropdown",
          code: componentCode,
          donation,
        }
      );

      const transformationInfo = await fetchComponentTransformationData(
        this.props.defaultLanguage,
        {
          inputType: "dropdown",
          code: componentCode,
          donation,
        }
      );

      let productInfo = {};
      if (separationInfo.length > 0) {
        productInfo = separationInfo[0];
      }

      if (transformationInfo.length > 0) {
        productInfo = transformationInfo[0];
      }

      let { error } = this.state;

      if (!productInfo?._id) {
        error.component =
          language[
            this.props.defaultLanguage
          ].no_product_available_for_labeling;
      }

      this.setState({
        productInfo,
        isFetchingComponents: false,
        error,
      });

      return productInfo;
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isFetchingComponents: false,
      });
    }
  };

  onGenerateLabel = async (donation) => {
    const user = await getStorage();
    let { error, component } = this.state;

    const productInfo = await this.getProductInfo(donation._id, component.code);
    if (!productInfo?._id) {
      error.component =
        language[this.props.defaultLanguage].no_product_available_for_labeling;

      this.setState({ error });
    }

    if (Object.keys(error).length > 0) {
      this.setState({
        isSubmitting: false,
      });
      return;
    }

    let requestedBody = {
      donation: donation._id,
      addedBy: user.id,
      component: productInfo.component._id,
      name: productInfo.name,
      code: productInfo.code,
      volume: productInfo.volume,
      productId: productInfo._id,
      center: donation?.center?._id,
      centerSite: donation?.centerSite?._id,
      status: "pending",
      bloodGroup: donation.bloodGroup,
      checkDonation: true,
    };

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };
    axios(options)
      .then((data) => {
        toastMessage(
          "success",
          `Label for ${component.label} was generated successfully`
        );

        this.handleShowModal("showModal", component.code, data.data);

        this.setState({
          donation: {},
          donationNumber: "",
          isSubmitting: false,
        });
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });

        toastMessage("error", error);

        return;
      });
  };

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <form>
              <div className="col-md-6">
                <div className="col-md-12">
                  <Select
                    loadOptions={(search) => this.getComponents(true, search)}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].component}
                    required
                    value={this.state.component}
                    onChange={(e) => this.onChangeText("component", e)}
                    error={this.state.error.component}
                    isLoading={this.state.isFetchingComponents}
                  />
                  <Input
                    placeholder={
                      language[this.props.defaultLanguage].search + "..."
                    }
                    label={language[this.props.defaultLanguage].donation_number}
                    required
                    value={this.state.donationNumber}
                    error={this.state.error.donationNumber}
                    onChange={(e) => this.onChangeText("donationNumber", e)}
                  />
                  {this.state.isFetchingProduct && (
                    <Button
                      text={language[this.props.defaultLanguage].generate_label}
                      onPress={(e) => {
                        e.preventDefault();
                        this.getDonation();
                      }}
                      isSubmitting={this.state.isSubmitting}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          showHeaderBottomBorder={false}
        >
          <PrintableLabel
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            productId={this.state?.selectedData?._id}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ComponentLabeling);
