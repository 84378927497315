import React, { createRef } from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Loading } from "../../../Loader";
import { Empty } from "../../../Empty";
import { fetchInventories } from "../../../../utils/queries/inventories/center";
import toastMessage from "../../../../utils/toastMessage";
import moment from "moment";
import { PrintComponent } from "../../../Print";
import Barcode from "react-barcode";
import { Checkbox } from "../../../Input";
import { Button } from "../../../Button";
import axios from "axios";
import { getStorage } from "../../../../utils/storage";
import BarcodeScannerInput from "../../../Barcode/BarcodeScannerInput";
import rbcLogo from "../../../../assets/rbc-logo.png";
import "./styles.css";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class PrintableLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      donation: {},
      isVerifying: false,
      isFocused: false,
    };

    this.scannerInputRef = createRef();
  }

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productId !== this.props.productId) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      this.setState({ isLoading });

      const data = await fetchInventories(this.props.defaultLanguage, {
        id: this.props.productId,
      });

      this.setState({ data, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage(error);
    }
  };

  renderInfo = ({
    title,
    description,
    barCode,
    isBlocked,
    type,
    displayValue = true,
    reason,
    isTestResult,
  }) => {
    return (
      <div className="d-flex align-items-center" style={{ minHeight: ".5rem" }}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 14, marginRight: 8 }}>{title}</span>
          {isBlocked && <span className="text-danger">(Blocked)</span>}
          {type === "checkBloodGroup" && (
            <div style={{ position: "relative" }}>
              <span
                style={{
                  fontSize: "2rem",
                  marginLeft: "1rem",
                  fontWeight: 600,
                  marginRight: 8,
                  left: -100,
                }}
                className={!isTestResult ? "noPrint" : ""}
              >
                <div>{barCode} </div>
              </span>
              {reason && <span className="text-danger"> {`(${reason})`}</span>}
            </div>
          )}
        </div>
        {description && (
          <div>
            <span style={{ fontSize: 14 }} className="text-bold">
              {description
                .replaceAll(":Positive", "")
                .replaceAll(" - Notification status", "")}
            </span>
          </div>
        )}
        {barCode && (
          <div style={{ width: 100 }}>
            <Barcode
              // displayValue={displayValue}
              // value={barCode}
              value={`${type}:${barCode}`}
              width={1}
              height={20}
            />
          </div>
        )}
      </div>
    );
  };

  handleCheck(field) {
    this.setState({
      [field]: !this.state[field],
    });
  }

  handleUpdate = async () => {
    try {
      this.setState({ isUpdating: true });

      await this.updateProductInfo();

      this.getData(true);

      this.setState({ isUpdating: false });
    } catch (error) {
      this.setState({ isUpdating: false });

      toastMessage("error", error);
    }
  };

  updateProductInfo = async () => {
    const { data } = this.state;

    if (data.length === 0)
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].no_data
      );

    this.setState({
      isUpdating: true,
    });

    const { donation, component, _id, center, centerSite } = data[0];
    const user = await getStorage();

    let requestedBody = {
      id: _id,
      donation: donation._id,
      addedBy: user.id,
      component: component?._id,
      name: component.name,
      code: component.code,
      // volume: component.volume,
      center: center?._id,
      centerSite: centerSite?._id,
      status: "pending",
      bloodGroup: donation.bloodGroup,
    };

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };

    await axios(options);
  };

  handleScan = async (e) => {
    try {
      let targetV = e?.target?.value;

      this.setState({ scannedValue: "" });
      this.setState({ scannedValue: targetV });

      if (!targetV || targetV === "") return;

      const split = targetV?.split(":");

      if (split.length === 0 && split.length === 2)
        return toastMessage(
          "error",
          "Invalid barcode format, only allowed format(donation number, blood group, component)"
        );

      let value = split[1];
      let key = split[0];

      if (!value || value === "") return;
      // toastMessage(
      //     "error",
      //     "Invalid bard code should be contain donation number or blood group or component code "
      //   );

      const { data } = this.state;

      if (data.length === 0) return toastMessage("error", "MISSING LABEL");

      this.setState({ isVerifying: false });

      const user = await getStorage();

      const requestedBody = {
        value,
        key,
        id: data[0]._id,
        verifiedBy: user.id,
      };
      // checkDonation: 12253;

      const options = {
        method: "POST",
        url: `${API_URL}/inventory/verify-label`,
        data: requestedBody,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await axios(options);

      await this.getData(false);

      this.setState({ isVerifying: false });
    } catch (error) {
      toastMessage("error", error);

      this.setState({ isVerifying: false });
    }
  };

  // handlePrint = async (verifyEnabled) => {
  //   try {
  //     if (!this.state.data?.length === 0)
  //       return toastMessage(
  //         "error",
  //         language[this.props.defaultLanguage].no_data
  //       );

  //     if (!this.state.data[0].isTestResult || this.state.data[0].isLabeled)
  //       return;

  //     const user = await getStorage();

  //     const isCheck =
  //       this.state.data[0].checkBloodGroup &&
  //       this.state.data[0].checkComponent &&
  //       this.state.data[0].checkDonation;

  //     if (!isCheck) return;

  //     this.setState({ isPrinting: true });

  //     const options = {
  //       url: `${API_URL}/inventory`,
  //       method: "PUT",
  //       data: {
  //         // status:
  //         //   this.state.data[0].checkBloodGroup &&
  //         //   this.state.data[0].checkComponent &&
  //         //   this.state.data[0].checkDonation
  //         //     ? "labelled"
  //         //     : undefined,
  //         id: this.state.data[0]._id,
  //         // isLabeled: true,
  //         // labelledBy: user.id,
  //         // verifiedBy: verifyEnabled ? user.id : undefined,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     };

  //     await axios(options);

  //     this.setState({ isPrinting: false });

  //     this.getData(true);
  //   } catch (error) {
  //     this.setState({ isPrinting: false });
  //     console.log(error);

  //     toastMessage("error", error);
  //   }
  // };

  renderLabelContent = () => {
    const isReactive = this.checkIsReactive();

    return (
      <div style={{ paddingRight: ".5rem" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={rbcLogo}
            style={{
              aspectRatio: 2 / 2,
              objectFit: "contain",
              width: "30%",
              opacity: 0.4,
              marginTop: "-10rem",
              // transform: "rotate(-30deg)",
              marginRight: "4rem",
            }}
          />
        </div>
        <div style={{ zIndex: 100 }}>
          {this.state.data.map((el, i) => {
            return (
              <div
                key={i}
                style={{
                  height: "106.8mm",
                }}
              >
                <div
                  style={{
                    // paddingTop: "1rem",
                    display: "flex",
                    // alignItems: "flex-start",
                    paddingRight: "1.8rem",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <div>
                    <span className="text-bold">BTD RWANDA KIGALI</span>
                    {/* {el?.center && (
                      <>
                        <br />
                        <span className="text-bold">{el?.center?.name}</span>
                      </>
                    )} */}
                  </div>
                </div>
                <div className="card-body">
                  {this.renderInfo({
                    title: el.name,
                    barCode: el.code,
                    type: "checkComponent",
                    displayValue: true,
                  })}
                  {this.renderInfo({
                    title: "Volume",
                    description: el.volume + " ML",
                  })}
                  {this.renderInfo({
                    title: "Phlebotomy Date",
                    description: moment(el?.donation?.createdAt).format("lll"),
                  })}
                  {this.renderInfo({
                    title: "Expiry Date",
                    description: moment(el.expiredDate).format("lll"),
                  })}
                  {this.renderInfo({
                    title: "Donation Number",
                    barCode: el.donation?.donationNumber,
                    isBlocked: !el?.donation?.isActive,
                    type: "checkDonation",
                    displayValue: true,
                  })}
                  {this.renderInfo({
                    title: "Blood Group",
                    barCode: !el?.donation?.isActive ? "N/A" : el.bloodGroup,
                    type: "checkBloodGroup",
                    displayValue: false,
                    reason: !isReactive
                      ? el?.donation?.blockInfo?.blockReason
                      : "",
                    isTestResult: el.isTestResult,
                  })}
                  {this.renderInfo({
                    title: "Storage Temperature",
                    description: el?.component?.storageTemperature?.name,
                  })}
                  {this.renderInfo({
                    title: "",
                    description: el.serologyResult,
                  })}
                  {this.renderInfo({
                    title: "",
                    description: el.bloodGroupingResult,
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  checkIsReactive() {
    const isReactive = this.state?.data?.some((el) => {
      // Split by colon to separate test name and result
      const results = el.serologyResult?.split(","); // Split by comma for multiple tests

      return results?.some((res) => {
        const [test, result] = res.split(":").map((str) => str.trim()); // Split test and result, trim whitespace
        // Check if it's "HIV" and if the result is "Reactive"
        return result === "Reactive";
      });
    });

    return isReactive;
  }

  render() {
    if (this.state.isLoading) {
      return (
        <center>
          <Loading className="fullscreen" />
        </center>
      );
    }

    if (this.state.data.length === 0) {
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    }

    const isReactive = this.checkIsReactive();

    return (
      <div style={{ paddingTop: "1rem", position: "relative" }}>
        {this.state.isVerifying && (
          <div className="verifying-label">
            <Loading />
            <center style={{ marginLeft: ".5rem" }}>
              <span>Verifying...</span>
            </center>
          </div>
        )}
        <PrintComponent
          // handlePrint={this.handlePrint.bind(this)}
          // isPrinting={this.state.isPrinting}
          buttonText={
            this.state.data?.length > 0 && this.state.data[0].isLabeled
              ? "Reprint Label"
              : "Print Label"
          }
        >
          {this.state.data?.length > 0 && this.state.data[0].isLabeled && (
            <>
              <center className="noPrint card-body">
                <div
                  className="badge badge-success badge-lg"
                  style={{
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    height: 20,
                    justifyContent: "center",
                  }}
                >
                  Labelled
                </div>
              </center>
            </>
          )}
          <div style={{ position: "relative" }}>
            {this.renderLabelContent(isReactive)}
            {isReactive && (
              <div className="reactive-to-test">
                {Array.from({ length: 10 }).map((_, index) => (
                  <span key={index}>REACTIVE</span>
                ))}
              </div>
            )}
          </div>
        </PrintComponent>

        {this.state.data.map((el, i) => {
          return (
            <div key={i}>
              <div className="card-body">
                {[
                  {
                    name: language[this.props.defaultLanguage]
                      .check_blood_group,
                    type: "checkBloodGroup",
                  },
                  {
                    name: language[this.props.defaultLanguage].check_component,
                    type: "checkComponent",
                  },
                  {
                    name: language[this.props.defaultLanguage]
                      .check_donation_number,
                    type: "checkDonation",
                  },
                ].map((checkEl, i) => {
                  console.log(el, checkEl);
                  return (
                    <Checkbox
                      key={i}
                      label={checkEl.name}
                      // onCheck={() => this.handleCheck(checkEl.type)}
                      checked={el[checkEl.type]}
                    />
                  );
                })}
              </div>
              <div className="modal-footer">
                <Button
                  text={language[this.props.defaultLanguage].cancel}
                  onPress={this.props.handleCloseModal}
                  className="btn-default"
                />
                <Button
                  text={language[this.props.defaultLanguage].update}
                  isSubmitting={this.state.isUpdating}
                  onPress={this.handleUpdate.bind(this, el)}
                />
              </div>
            </div>
          );
        })}
        {this.state.data.length > 0 && !this.state.data[0].isLabeled && (
          <input
            onChange={(e) => this.handleScan(e)}
            autoFocus
            value={this.state.scannedValue}
            style={{ position: "absolute", left: "-9999px" }}
          />
        )}
        <BarcodeScannerInput
          onScan={(txt) => this.handleScan(txt)}
          targetInputRef={this.scannerInputRef.current}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PrintableLabel);
