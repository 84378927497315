import React from "react";
import moment from "moment";
import deepFind, { returnMultiAnswer } from "../../utils/deepFind";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { TimeAgo } from "../TimeAgo";
import capitalizeText from "../../utils/capitalizeText";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import { connect } from "react-redux";
import ButtonToClipboard from "../Button/ButtonToClipboard";
import userPhotoPlaceholder from "../../assets/userPhotoPlaceholder.png";
import language from "../../language";
class Row extends React.PureComponent {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user: user });
  };
  render() {
    const { headers, rowPress, actions, item, keyRowIndex } = this.props;
    return (
      <tr key={keyRowIndex}>
        {headers &&
          headers.map((header, h) => {
            let hasActionBtn =
              header.key === "action" || header.key === "multiple-actions";

            if (
              Object.keys(header).includes("checkValue") &&
              Object.keys(header).includes("checkKey") &&
              header.checkValue !== deepFind(item, header.checkKey)
            ) {
              hasActionBtn = false;
            }

            let find_text = deepFind(item, header.key)
              ? deepFind(item, header.key)
              : "-";
            let text =
              header.isMoment && find_text !== "-"
                ? moment(find_text).format(header.formatTime)
                : `${find_text} `;

            return (
              <>
                {hasActionBtn ? (
                  <td key={h}>
                    {header.key === "multiple-actions" ? (
                      <div className="table-raw-multiple-actions">
                        {header.actions.map((btn, i) => {
                          return (
                            <div
                              key={i}
                              className="table-raw-multiple-actions-button"
                              title={btn.title}
                            >
                              <Button
                                className={`btn-sm btn-transparent ${btn.className}`}
                                icon={btn.icon}
                                onPress={() => btn.onPress(item)}
                                text={btn.text || btn.name || btn.title || ""}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="dropdown">
                        <Button
                          className="btn-transparent btn-sm "
                          type="dropdown"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          icon="more_vert"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1 table-actions-menus-container"
                        >
                          {actions &&
                            actions.map((action, a) => {
                              if (!action) return;
                              if (!action.canAccess) return;
                              return (
                                <Link
                                  key={a}
                                  className={`dropdown-item ${
                                    !action.canAccess && "text-disabled-3"
                                  } table-actions-menus-item`}
                                  to={action.route ? action.route : "#"}
                                  onClick={() =>
                                    action.onPress(item, keyRowIndex)
                                  }
                                  icon={action.icon}
                                >
                                  {action.icon && (
                                    <div className="icon-container">
                                      <span
                                        className={
                                          process.env.REACT_APP_ICONS_TYPE
                                        }
                                      >
                                        {action.icon}
                                      </span>
                                    </div>
                                  )}
                                  <span>{action.name}</span>
                                </Link>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </td>
                ) : (
                  <td key={h}>
                    {header.type === "labResult" ? (
                      <Button
                        text={language[this.props.defaultLanguage].view}
                        onPress={() => header.onPress(item)}
                      />
                    ) : header.type === "photo" ? (
                      <div>
                        <img
                          onClick={() =>
                            header.onPress ? header.onPress(item) : null
                          }
                          src={
                            text !== "- "
                              ? `data:image/png;base64,${text}`
                              : userPhotoPlaceholder
                          }
                          style={{
                            width: 45,
                            height: 45,
                            borderRadius: "50%",
                            aspectRatio: 3 / 2,
                            border: "2px solid var(--light-color)",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : header.type === "link" ? (
                      <div>
                        <div className="d-flex align-items-center tableLinkContainer">
                          <Link
                            className="text-primary text-hover-decoration text-overflow cell-width"
                            onClick={() =>
                              header.onPress
                                ? header.onPress(item, keyRowIndex)
                                : null
                            }
                            to="#"
                            title={!returnMultiAnswer(item, header.key) && text}
                            style={header.styles || {}}
                          >
                            {capitalizeText(text) + ""}
                          </Link>

                          <div className="copyTableData">
                            {header.copyEnabled && (
                              <ButtonToClipboard textToCopy={text} />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : header.type === "timeAgo" ? (
                      <div className="text-overflow cell-width">
                        {text.replaceAll(" ", "") === "-" ? (
                          <span>-</span>
                        ) : (
                          <TimeAgo
                            date={moment(text.replaceAll(" ", "")).format(
                              "lll"
                            )}
                          />
                        )}
                      </div>
                    ) : header.type === "status" ? (
                      <div
                        className={`badge badge-default badge-${text.toLowerCase()}`}
                      >
                        <span>{capitalizeText(text) + ""}</span>
                      </div>
                    ) : header.type === "countCheck" && Number(text) ? (
                      <div className="d-flex align-items-center gap-2">
                        {Array(Number(text))
                          .fill()
                          .map((_, i) => {
                            return (
                              <div
                                className={`status-icon-container success`}
                                key={i}
                              >
                                <span
                                  className={process.env.REACT_APP_ICONS_TYPE}
                                >
                                  {icons.success}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          header.key !== "action" && rowPress
                            ? rowPress(item, keyRowIndex)
                            : null
                        }
                        title={!returnMultiAnswer(item, header.key) && text}
                      >
                        {returnMultiAnswer(item, header.key) ? (
                          returnMultiAnswer(item, header.key)
                        ) : text?.replace(" ", "")?.toLowerCase() === "true" ? (
                          <div className={`status-icon-container success`}>
                            <span className={process.env.REACT_APP_ICONS_TYPE}>
                              {icons.success}
                            </span>
                          </div>
                        ) : text?.replace(" ", "")?.toLowerCase() ===
                          "false" ? (
                          <div className={`status-icon-container failed`}>
                            <span className={process.env.REACT_APP_ICONS_TYPE}>
                              {icons.fail}
                            </span>
                          </div>
                        ) : (
                          <span
                            style={header.styles || {}}
                            className="text-overflow cell-width"
                          >
                            {header.isMoment ? text : capitalizeText(text) + ""}
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                )}
              </>
            );
          })}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Row);
