import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import { fetchTransportationTemperatures } from "../../utils/queries/transportationTemperatures";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewProfile extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    expirationValue: "",
    componentName: "",
    code: "",
    expirationValue: "",
    volume: "",
    concentration: "",
  };

  componentDidMount = async () => {
    if (this.props._id) {
      this.setState({
        componentName: this.props.name,
        code: this.props.code,
        expirationValue: this.props.expirationValue,
        expirationType: {
          label: this.props.expirationType,
          value: this.props.expirationType,
        },
        storageTemperature: this.props.storageTemperature
          ? {
              label:
                this.props.storageTemperature?.code +
                " - " +
                this.props.storageTemperature?.name,
              value: this.props.storageTemperature._id,
            }
          : null,
        volume: this.props.volume,
      });
    }
  };

  getTemperatures = async (code) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingTemperatures: true });

      const data = await fetchTransportationTemperatures(defaultLanguage, {
        page,
        limit,
        code: code ? code : undefined,
        inputType: "dropdown",
      });

      this.setState({
        isLoadingTemperatures: false,
      });

      return data;
    } catch (error) {
      this.setState({
        isLoadingTemperatures: false,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let {
      code,
      componentName,
      error,
      expirationValue,
      expirationType,
      volume,
    } = this.state;

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    if (componentName === "") {
      error.componentName =
        language[this.props.defaultLanguage].component_name_required;
    }

    if (!expirationType) {
      error.expirationType =
        language[this.props.defaultLanguage].expiration_type_required;
    }

    if (expirationValue === "") {
      error.expirationValue =
        language[this.props.defaultLanguage].expiration_value_required;
    }

    // if (volume === "") {
    //   error.volume = language[this.props.defaultLanguage].volume_required;
    // }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          componentName,
          expirationType,
          expirationValue,
          code,
          volume,
          storageTemperature,
          concentration,
          maxVolume,
        } = this.state,
        url = `${API_URL}/components`,
        method = "POST",
        { _id } = this.props,
        user = await getStorage();

      let requestedBody = {
        name: componentName,
        expirationType: expirationType.value,
        expirationValue,
        status: "active",
        code,
        volume,
        maxVolume,
        storageTemperature: storageTemperature?.value,
        concentration,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "data_update_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .component_name_placeholder
                }
                label={language[this.props.defaultLanguage].component_name}
                required
                value={this.state.componentName}
                onChange={(e) => this.onChangeText("componentName", e)}
                error={this.state.error.componentName}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].code_placeholder
                }
                label={language[this.props.defaultLanguage].code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>

            <div className="col-md-4">
              <Select
                options={[
                  {
                    label: "Days",
                    value: "days",
                  },
                  {
                    label: "Hours",
                    value: "hours",
                  },
                  {
                    label: "Minutes",
                    value: "minutes",
                  },
                  {
                    label: "Months",
                    value: "months",
                  },
                  {
                    label: "Years",
                    value: "years",
                  },
                  {
                    label: "Rejected",
                    value: "rejected",
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].expiration_type}
                required
                value={this.state.expirationType}
                onChange={(e) => this.onChangeText("expirationType", e)}
                error={this.state.error.expirationType}
              />
            </div>
            <div className="col-md-8">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .expiration_value_placeholder
                }
                label={language[this.props.defaultLanguage].expiration_value}
                required
                value={this.state.expirationValue}
                onChange={(e) => this.onChangeText("expirationValue", e)}
                error={this.state.error.expirationValue}
                type="number"
              />
            </div>

            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].volume_placeholder
                }
                label={language[this.props.defaultLanguage].volume}
                value={this.state.volume}
                onChange={(e) => this.onChangeText("volume", e)}
                error={this.state.error.volume}
                type="number"
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].volume_placeholder
                }
                label={language[this.props.defaultLanguage].max_volume}
                value={this.state.maxVolume}
                onChange={(e) => this.onChangeText("maxVolume", e)}
                error={this.state.error.maxVolume}
                type="number"
              />
            </div>
          </div>

          <div className="col-md-12">
            <Select
              loadOptions={this.getTemperatures}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].storage_temperature}
              required
              value={this.state.component}
              onChange={(e) => this.onChangeText("storageTemperature", e)}
              error={this.state.error.storageTemperature}
            />
          </div>
          <div className="col-md-12">
            <Input
              placeholder={
                language[this.props.defaultLanguage].concentration_placeholder
              }
              label={language[this.props.defaultLanguage].concentration}
              required
              value={this.state.concentration}
              onChange={(e) => this.onChangeText("concentration", e)}
              error={this.state.error.concentration}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewProfile);
