import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import { fetchTransportationTemperatures } from "../../../utils/queries/transportationTemperatures";
import { fetchAllComponents } from "../../../utils/queries/production/components";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewProfile extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    componentName: "",
    code: "",
    components: [],
  };

  getComponents = async (code) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingComponent: true });

      const data = await fetchAllComponents(defaultLanguage, {
        page,
        limit,
        code: code && code !== "" ? code : undefined,
        inputType: "dropdown",
      });

      this.setState({
        isLoadingComponent: false,
      });

      return data;
    } catch (error) {
      this.setState({
        isLoadingComponent: false,
      });
    }
  };

  componentDidMount = async () => {
    if (this.props._id) {
      this.setState({
        componentName: this.props.name,
        code: this.props.code,
        // splits: {
        //   label: this.props.splits,
        //   value: this.props.splits,
        // },
        transportationTemperature: {
          label: this.props.transportationTemperature?.code,
          value: this.props.transportationTemperature?._id,
        },
        component: this.props.components?.map((el) => {
          return {
            label: el.code + " - " + el.name,
            value: el._id,
          };
        }),
      });
    }
  };

  getTransportationTemperatures = async (q) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingComponent: true });

      const data = await fetchTransportationTemperatures(defaultLanguage, {
        page,
        limit,
        q,
        inputType: "dropdown",
      });

      this.setState({
        isLoadingTemperature: false,
      });

      return data;
    } catch (error) {
      this.setState({
        isLoadingTemperature: false,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "component") {
      let name = e.map((el) => el.name).join(",");
      this.setState({ componentName: name });
    }
  };

  validateForm() {
    let { code, componentName, error, component, splits } = this.state;

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    if (componentName === "") {
      error.componentName =
        language[this.props.defaultLanguage].component_name_required;
    }

    if (!component) {
      error.component = language[this.props.defaultLanguage].component_required;
    }

    if (splits === "") {
      error.splits = language[this.props.defaultLanguage].splits_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          componentName,
          component,
          code,
          transportationTemperature,
          splits,
        } = this.state,
        url = `${API_URL}/componentProfile`,
        method = "POST",
        { _id } = this.props,
        user = await getStorage();

      let requestedBody = {
        name: componentName,
        components: component?.map((el) => el.value),
        status: "active",
        type: "transformation",
        code,
        // splits: splits.value,
        transportationTemperature: transportationTemperature?.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "data_update_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].code_placeholder
                }
                label={language[this.props.defaultLanguage].code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>

            <div className="col-md-12">
              <Select
                loadOptions={this.getComponents}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].result_component}
                required
                value={this.state.component}
                onChange={(e) => this.onChangeText("component", e)}
                error={this.state.error.component}
                isMulti
              />
            </div>

            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.componentName}
                onChange={(e) => this.onChangeText("componentName", e)}
                error={this.state.error.componentName}
              />
            </div>

            {/* <div className="col-md-12">
              <Select
                options={[
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                  {
                    label: "Thawed plasma further transformed/split",
                    value: "thawed",
                  },
                  {
                    label: "Final",
                    value: "final",
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].splits}
                required
                value={this.state.splits}
                onChange={(e) => this.onChangeText("splits", e)}
                error={this.state.error.splits}
              />
            </div> */}

            <div className="col-md-12">
              <Select
                loadOptions={this.getTransportationTemperatures}
                placeholder={language[this.props.defaultLanguage].select}
                label={
                  language[this.props.defaultLanguage]
                    .transportation_temperature
                }
                required
                value={this.state.transportationTemperature}
                onChange={(e) =>
                  this.onChangeText("transportationTemperature", e)
                }
                error={this.state.error.transportationTemperature}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewProfile);
