import React from "react";
import { DropdownButton } from "../Button";

import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";
import { Loading } from "../Loader";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { onAddFilter } from "../../actions/Filters";

let copyData = [];

class CentersFilter extends React.Component {
  state = {
    isLoading: true,
    data: [],
    page: 1,
    limit: 50,
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.categoryType !== this.props.categoryType) {
      await this.getData(true);
    }
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;
      const { page, limit } = this.state;

      this.setState({ isLoading });

      const { data, count } = await bloodCenterSiteQuery(defaultLanguage, {
        page,
        limit,
        type: "dropdown",
      });

      this.setState({
        data: data,
        isLoading: false,
        count,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onCheck = async (el) => {
    let { filters } = this.props;
    let { centerSites = [] } = filters;

    const index = centerSites.findIndex((sEl) => sEl.value === el._id);

    if (index === -1) {
      centerSites.push({
        value: el._id,
        label: el.name,
      });
    } else {
      centerSites.splice(index, 1);
    }

    await this.props.dispatch(
      onAddFilter({
        centerSites,
      })
    );

    this.props.handleApplyFilters && this.props.handleApplyFilters();
  };

  handleSearch(e) {
    const searchValue = e?.target?.value;
    this.setState({ searchValue });

    let results = [];
    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      ) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.centerSite}
        text={language[this.props.defaultLanguage].filter_center_site}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.centerSite}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].filter_center_site}
          borderBottom
          closeButton
          isSearch
        />
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.state?.data?.map((el, i) => {
              return (
                <Checkbox
                  label={el.name}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={this.props?.filters?.centerSite?.find(
                    (sEl) => sEl._id === el._id
                  )}
                />
              );
            })
          )}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(CentersFilter);
