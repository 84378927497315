import React from "react";
import { connect } from "react-redux";
import { Inventories } from "../../../../components/Production/inventory";
import { withRouter } from "../../../../utils/withRouter";
import language from "../../../../language";
import BloodSeparationsScreen from "../ComponentSeparation/BloodSeparationsScreen";
import BloodTransformationScreen from "../ComponentTransformation/BloodTransformationScreen";
import { Tabs } from "../../../../components/Tabs";
import { getStorage } from "../../../../utils/storage";
import accessMenu from "../../../../utils/accessMenu";
import ComponentLabeling from "../../../../components/Production/ComponentLabeling/Labeling";
import NewReception from "../../../../components/Production/inventory/NewReceiption";
import NewSeparation from "../../../../components/Production/ComponentSeparation/NewSeparation";
import NewComponentTransformation from "../../../../components/Production/ComponentTransformation/NewComponentTransformation";
import Separations from "../../../../components/Production/ComponentSeparation/Separations";

class InventoriesScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    let {
      canReadProductionInventory,
      canReadComponentSeparation,
      canReadComponentTransformation,
      canReadLabeling,
    } = accessMenu();
    let tabOptions = [];

    if (canReadProductionInventory) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].reception,
        data: (
          <div className="card">
            <div className="card-body">
              <div className="col-md-6">
                <NewReception {...this.props.routeParams} />
              </div>
            </div>
          </div>
        ),
      });
    }

    if (canReadProductionInventory) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].inventory,
        data: <Inventories {...this.props.routeParams} />,
      });
    }

    if (canReadComponentSeparation) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_separation,
        data: (
          <Tabs
            options={[
              {
                title: language[this.props.defaultLanguage].new_record,
                data: <NewSeparation {...this.props.routeParams} />,
              },
              {
                title: language[this.props.defaultLanguage].historical_data,
                data: <Separations type="separation" />,
              },
            ]}
          />
        ),
      });
    }

    if (canReadLabeling) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_to_label,
        data: <ComponentLabeling {...this.props.routeParams} />,
      });
    }

    if (canReadComponentTransformation) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_transformation,
        data: (
          <div className="card">
            <div className="card-body">
              <NewComponentTransformation {...this.props.routeParams} />
            </div>
          </div>
        ),
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(InventoriesScreen));
