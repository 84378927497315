import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import bloodTestQuery, {
  deletebloodtest,
  searchBloodTest,
} from "../../utils/queries/bloodTest/bloodTestQuery";
import bloodDonationQuery from "../../utils/queries/bloodDonationQuery";
import { DeleteModal, Modal } from "../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import SearchDonation from "../BloodCollection/SearchDonation";
import icons from "../../constants/icons";
import { ROLE_BLOOD_TEST } from "../../constants/ROLES";
import BloodTestFilters from "../Filters/bloodTestFilters/BloodTestFilters";
import Worksheets from "./export/Worksheets";
import LabResults from "./LabResults";
import moment from "moment";
import toastMessage from "../../utils/toastMessage";

class BloodTesting extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.filters !== this.props.filters) {
      await this.getData(true);
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, donationId, isSearch, filters } = this.props;
      console.log(filters);

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      let params = {
        page,
        limit,
        donorId: this.props.donorId,
        donation: donationId,
        center: centerRole,
        centerSite: centerSiteRole,
        ...search,
        startCollectionDate: moment(filters.startDate)
          .startOf("D")
          .format("YYYY-MM-DD HH:mm"),
        endCollectionDate: moment(filters.endDate)
          .endOf("D")
          .format("YYYY-MM-DD HH:mm"),
      };

      if (params?.search?.q) {
        delete params.startCollectionDate;
        delete params.endCollectionDate;
      }

      if (isSearch) {
        delete params.startCollectionDate;
        delete params.endCollectionDate;
      }

      const { data, count } = await bloodTestQuery(defaultLanguage, params);

      if (isSearch && data.length === 1) {
        window.location.href = `/dashboard/testing/${data[0]._id}/${data[0]?.donation?.donationNumber}`;

        return;
      }

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      this.props.showLabResult && {
        title: language[this.props.defaultLanguage].lab_results,
        type: "labResult",
        onPress: (item) => {
          this.handleShowModal("showLabResults", item.code, item);
        },
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/testing/${item._id}/${item.donation.donationNumber}`),
        styles: {
          textTransform: "uppercase",
        },
        copyEnabled: true,
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].activate_donation,
        key: "activateDonation",
      },
      // {
      //   title: language[this.props.defaultLanguage].date,
      //   key: "createdAt",
      //   isMoment: true,
      //   formatTime: "lll",
      // },
      {
        title: language[this.props.defaultLanguage].blood_grouping_tested,
        key: "bloodGroupingTestCount",
        type: "countCheck",
      },
      {
        title: language[this.props.defaultLanguage].serology_tested,
        key: "serologyTestCount",
        type: "countCheck",
      },
      {
        title: language[this.props.defaultLanguage].collection_date,
        key: "donation.collectionDate",
        isMoment: true,
        formatTime: "ll",
      },
      {
        title: language[this.props.defaultLanguage].collection_start_time,
        key: "donation.startTime",
      },
      {
        title: language[this.props.defaultLanguage].collection_end_time,
        key: "donation.endTime",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers.filter(Boolean);
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deletebloodtest(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearchButton = async () => {
    try {
      const { search_text } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ searching: true });

      const data = await searchBloodTest(defaultLanguage, {
        donorNumber: search_text,
      });

      console.log("searched results", data);
      this.setState({ data, searching: false });
    } catch (error) {
      console.log("Blood test error:", error);
      this.setState({ searching: false });
    }
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    const { new_blood_test } = language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) => this.onChangeText("search_text", e)}
          addSearchButton
          searching={this.state.searching}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_BLOOD_TEST,
            operation: "create",
          })}
          addButtonText={new_blood_test}
          handleAddPressed={() =>
            this.state.data.length === 1
              ? (window.location.href = `/dashboard/testing/${this.state.data[0]._id}/${this.state?.data[0]?.donation?.donationNumber}`)
              : this.handleShowModal(
                  "showSearchModal",
                  language[this.props.defaultLanguage].search
                )
          }
          rowPress={(item) =>
            (window.location.href = `/dashboard/testing/${item._id}/${item?.donation?.donationNumber}`)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
            {
              type: "dropdown",
              filterName: "date_range",
              title: language[this.props.defaultLanguage].date_range,
              button_type: "dropdown",
              handleApplyFilters: () => this.getData(),
            },
            {
              type: "button",
              title: language[this.props.defaultLanguage].filters,
              button_type: "button",
              icon: icons.filters,
              onPress: (item) =>
                this.handleShowModal(
                  "filtersModal",
                  language[this.props.defaultLanguage].filters
                ),
            },
            {
              type: "button",
              title: language[this.props.defaultLanguage].export_,
              button_type: "button",
              icon: icons.export,
              onPress: (item) =>
                this.handleShowModal(
                  "exportModal",
                  language[this.props.defaultLanguage].export_
                ),
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_BLOOD_TEST,
                operation: "testResult",
              }),
              name: language[this.props.defaultLanguage].results,
              onPress: (item) =>
                (window.location.href = `/dashboard/testing/${item._id}`),
              icon: icons.eye_on,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_BLOOD_TEST,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: (item) =>
                this.handleShowModal(
                  "showDeleteModal",
                  language[this.props.defaultLanguage].delete,
                  item
                ),
              icon: icons.delete,
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSearchModal")}
          show={this.state.showSearchModal}
          title={this.state.modalTitle}
        >
          <SearchDonation
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSearchModal"
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "filtersModal")}
          show={this.state.filtersModal}
          title={this.state.modalTitle}
        >
          <div className="card-body">
            <div className="row">
              <BloodTestFilters
                fetchData={(params) => this.getData(true, params)}
                handleCloseModal={this.handleCloseModal.bind(
                  this,
                  "filtersModal"
                )}
              />
            </div>
          </div>
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "exportModal")}
          show={this.state.exportModal}
          title={this.state.modalTitle}
          size="fullscreen"
        >
          <div className="card-body">
            <div className="row">
              <Worksheets />
            </div>
          </div>
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData._id}
            getData={this.getData.bind(this)}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showLabResults")}
          show={this.state.showLabResults}
          title={this.state.modalTitle}
          size="lg"
        >
          <LabResults testId={this.state.selectedData?._id} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodTesting);
