import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import hospitalQuery from "../../../utils/queries/hospitalQuery";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import Packages from "../Packages";
import icons from "../../../constants/icons";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import { fetchPatients } from "../../../utils/queries/patient";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";
import { fetchBloodBanks } from "../../../utils/queries/bloodBank";
import {
  generateRandomNumber,
  generateRandomString,
} from "../../../utils/random";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const statusOptions = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Dispatched",
    value: "dispatched",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
];

class NominativeRequest extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    donors: [],
    distributors: [],
    hospitals: [],
    numberOfPackages: "",
    isActive: availableOptions[0],
    description: "",
    approvals: [],
    packages: [],
    centers: [],
    centerSites: [],
    status: statusOptions[0],
  };

  componentDidMount = async () => {
    this.setState({
      requestNo:
        generateRandomString(4).toUpperCase() +
        "-" +
        generateRandomNumber(0, 1000000),
    });

    this.getHospitals(true);

    await this.setState({ ...this.props });

    if (this.props._id) {
      this.setState({
        bloodBank:
          this.props.bloodBank && this.props.bloodBank._id
            ? {
                label: this.props.bloodBank?.center?.name,
                value: this.props.bloodBank._id,
              }
            : null,
        distributor:
          this.props.distributor && this.props.distributor._id
            ? {
                label: this.props.distributor.name,
                value: this.props.distributor._id,
              }
            : null,
        hospital:
          this.props.hospital && this.props.hospital._id
            ? {
                label: this.props.hospital.name,
                value: this.props.hospital._id,
              }
            : null,
        packages: this.props.packages || [],
        approvals: this.props.approvals || [],
        isActive: {
          label: this.props.isActive + "",
          value: this.props.isActive + "",
        },
        status: statusOptions.find((el) => el.value === this.props.status),
      });
    }
  };

  getHospitals = async (isFetchingHospital) => {
    try {
      this.setState({
        isFetchingHospital,
      });

      const { hospitalRole } = defineRoleCenterSite();

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
        id: hospitalRole,
      });

      this.setState({
        hospitals: data,
        isFetchingHospital: false,
        hospital: data[0],
      });
    } catch (error) {
      this.setState({ isFetchingHospital: false });
    }
  };

  getPatients = async (isFetchingPatient, search) => {
    try {
      this.setState({
        isFetchingPatient,
      });

      const { hospitalRole } = defineRoleCenterSite();

      const data = await fetchPatients(this.props.defaultLanguage, {
        type: "dropdown",
        hospital: hospitalRole,
        q: search ? search : undefined,
      });

      this.setState({
        patients: data,
        isFetchingPatient: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingPatient: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  onCheck(field, value) {
    this.setState({
      [field]: value || this.state[field],
    });
  }

  onSavePackages(packages) {
    this.setState({
      packages,
    });
  }

  validateForm() {
    let { error, hospital, packages, description, requestNo } = this.state;

    if (!hospital) {
      error.hospital = language[this.props.defaultLanguage].hospital_required;
    }

    if (packages.length === 0) {
      error.packages = language[this.props.defaultLanguage].packages_required;
    }

    if (requestNo === "") {
      error.requestNo =
        language[this.props.defaultLanguage].request_number_required;
    }
    console.log(error);

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          hospital,
          _id,
          description,
          status,
          packages,
          bloodBank,
          requestNo,
          isUrgent,
          clinicalInformation,
          patient,
          bloodComponentNeededOn,
          historyPregnancy,
          previouslyTransfused,
          lastTransfused,
          testRequired,
        } = this.state,
        url = `${API_URL}/bloodrequest`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        type: "nominative",
        requestNo,
        hospital: hospital.value,
        description: description,
        packages: packages,
        bloodBank: bloodBank?.value,
        account: user.id,
        isUrgent,
        clinicalInformation,
        patient: patient?.value,
        bloodComponentNeededOn,
        historyPregnancy,
        previouslyTransfused,
        lastTransfused,
        testRequired: testRequired?.map((el) => el.value),
        bloodComponentNeededOn,
      };

      if (status?.value) {
        requestedBody.status = status.value;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_bloodrequest_success"
                : "add_bloodrequest_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  getBloodBanks = async (isFetchingBloodBank, search = {}) => {
    try {
      const { hospital } = this.state;
      this.setState({
        isFetchingBloodBank,
      });

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        type: "dropdown",
        hospital: hospital?.value,
        ...search,
      });

      this.setState({
        bloodBanks: data,
        isFetchingBloodBank: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingBloodBank: false });
    }
  };

  render() {
    console.log(this.state.error);
    return (
      <div>
        <div
          className="card-body"
          style={{ maxHeight: "60vh", overflowY: "auto" }}
        >
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .request_number_placeholder
                }
                label={language[this.props.defaultLanguage].request_number}
                required
                value={this.state.requestNo}
                onChange={(e) => this.onChangeText("requestNo", e)}
                error={this.state.error.requestNo}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.hospitals}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].requested_by}
                required
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isFetchingHospital}
              />
            </div>
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getBloodBanks(true, e)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].blood_bank}
                required
                value={this.state.bloodBank}
                onChange={(e) => this.onChangeText("bloodBank", e)}
                error={this.state.error.bloodBank}
                isLoading={this.state.isFetchingBloodBank}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .clinical_information_placeholder
                }
                label={
                  language[this.props.defaultLanguage].clinical_information
                }
                required
                value={this.state.clinicalInformation}
                onChange={(e) => this.onChangeText("clinicalInformation", e)}
                error={this.state.error.clinicalInformation}
                textarea
              />
            </div>

            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getPatients(true, e)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].patient}
                required
                value={this.state.patient}
                onChange={(e) => this.onChangeText("patient", e)}
                error={this.state.error.patient}
                isLoading={this.state.isFetchingPatient}
              />
            </div>
            <div className="col-md-12">
              <Checkbox
                label={language[this.props.defaultLanguage].urgent}
                onChange={(e) => this.onCheck("isUrgent")}
                value={this.state.isUrgent}
              />
            </div>
            <div className="col-md-12">
              <Checkbox
                label={
                  language[this.props.defaultLanguage].previously_transfused
                }
                onChange={(e) => this.onCheck("previouslyTransfused")}
                value={this.state.previouslyTransfused}
              />
            </div>
            <hr />
            <div className="col-md-12">
              <CheckboxQuestionnaire
                questionText={
                  language[this.props.defaultLanguage].history_of_pregnancy
                }
                answers={[
                  {
                    field: "historyPregnancy",
                    value: language[this.props.defaultLanguage].yes,
                    onPress: () => this.onCheck("historyPregnancy", true),
                  },
                  {
                    field: "historyPregnancy",
                    value: language[this.props.defaultLanguage].no,
                    onPress: () => this.onCheck("historyPregnancy", false),
                  },
                ]}
                checked={this.state.historyPregnancy === true ? "Yes" : "No"}
                error={this.state.error.historyPregnancy}
              />
            </div>
            <hr />
            {this.state.previouslyTransfused && (
              <div className="col-md-12">
                <Input
                  label={
                    language[this.props.defaultLanguage]
                      .if_yes_when_last_transfused
                  }
                  required
                  value={this.state.lastTransfused}
                  onChange={(e) => this.onChangeText("lastTransfused", e)}
                  error={this.state.error.lastTransfused}
                />
              </div>
            )}
            <div className="col-md-12">
              <Select
                options={[
                  {
                    label: "ABO/RH",
                    value: "ABO/RH",
                  },
                  {
                    label: "Antibody Screening",
                    value: "Antibody Screening",
                  },
                  {
                    label: "Crossmatch/Compatibility testing",
                    value: "Crossmatch/Compatibility testing",
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].test_required}
                required
                value={this.state.testRequired}
                onChange={(e) => this.onChangeText("testRequired", e)}
                error={this.state.error.testRequired}
                isMulti
                isCreatable
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bordered">
                <div
                  className="card-header"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="material-symbols-outlined">
                    {icons.package}
                  </span>
                  <span className="text-bold">
                    {language[this.props.defaultLanguage].request_packages}
                  </span>
                </div>
                <div className="card-body">
                  <Packages
                    data={this.state.packages}
                    onSavePackages={(packages) => this.onSavePackages(packages)}
                  />
                </div>
              </div>
            </div>
            {this.state.error.packages && (
              <span className="text-error">{this.state.error.packages}</span>
            )}
          </div>
          <div className="col-md-12">
            <Select
              options={statusOptions}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].status}
              required
              value={this.state.status}
              onChange={(e) => this.onChangeText("status", e)}
              error={this.state.error.status}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NominativeRequest);
