import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import bloodDonationQuery, {
  deleteBloodDonation,
} from "../../utils/queries/bloodDonationQuery";
import { ConfirmationModal, DeleteModal, Modal } from "../Modal";
import NewCollection from "./NewCollection";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { SearchDonor } from "../Donors";
import icons from "../../constants/icons";
import toastMessage from "../../utils/toastMessage";
import axios from "axios";
import ViewVitalSign from "../VitalSigns/ViewSingleSign";
import { ROLE_BLOOD_TEST, ROLE_COLLECTIONS } from "../../constants/ROLES";
import handleFilters from "../../utils/handleFilters";

let copyData = [];

class BloodCollection extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData();
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  getData = async (search = {}) => {
    try {
      const { page, limit } = this.state;
      const {
        defaultLanguage,
        filters,
        centerId,
        centerSiteId,
        customFilters,
      } = this.props;

      this.setState({ isLoading: true });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      let params = {
        page,
        limit,
        donor: this.props.donorId,
        id: this.props.donationId,
        center: centerId || centerRole,
        centerSite: centerSiteId || centerSiteRole,
        ...handleFilters(filters),
        ...search,
        ...(customFilters || {}),
      };

      if (this.props.donorId) {
        delete params.startDate;
        delete params.endDate;
      }

      const { data, count } = await bloodDonationQuery(defaultLanguage, params);
      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData();
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item._id}/${
            item.donor._id
          }/${item.donor.firstName + " " + item.donor.lastName}`),
        copyEnabled: true,
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].donor,
        key: "donor.donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item._id}/${
            item.donor._id
          }/${item.donor.firstName + " " + item.donor.lastName}`),
        styles: {
          textTransform: "uppercase",
        },
        copyEnabled: true,
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodInfo.bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "bloodInfo.volume",
      },
      {
        title: language[this.props.defaultLanguage].vital_sign,
        key: "collectionDate",
        type: "link",
        isMoment: true,
        formatTime: "lll",
        onPress: (item) =>
          this.handleShowModal(
            "showVitalSignModal",
            item._id || item,
            item?.vitalSign
          ),
        copyEnabled: true,
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].equipment_code,
        key: "equipment.code",
      },
      {
        title: language[this.props.defaultLanguage].equipment_name,
        key: "equipment.name",
      },
      {
        title: language[this.props.defaultLanguage].collection_date,
        key: "collectionDate",
        isMoment: true,
        formatTime: "ll",
      },
      {
        title: language[this.props.defaultLanguage].start_time,
        key: "startTime",
      },
      {
        title: language[this.props.defaultLanguage].end_time,
        key: "endTime",
      },
      {
        title: language[this.props.defaultLanguage].reaction,
        key: "reaction.isReaction",
      },
      {
        title: language[this.props.defaultLanguage].reaction_category,
        key: "reaction.category",
      },
      {
        title: language[this.props.defaultLanguage].reaction_comment,
        key: "reaction.comment",
      },
      {
        title: language[this.props.defaultLanguage].tested,
        key: "bloodInfo.tested",
      },
      {
        title: language[this.props.defaultLanguage].is_active,
        key: "isActive",
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].phlebotomist,
        key: "phlebotomist.email",
      },
      {
        title: language[this.props.defaultLanguage].collection_type,
        key: "collectionType.collectionTypeNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].collection_type,
        key: "collectionType.name",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].blood_donation_recorder,
        key: "addedBy.firstName",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onUpdateStatus = async (status, modal) => {
    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isUpdating: true,
      });

      let url = `${process.env.REACT_APP_BBMS_BASE_API}/blooddonation`,
        method = "PUT",
        user = await getStorage(),
        { selectedData, reason } = this.state;

      const options = {
        method,
        url,
        data: {
          status,
          id: selectedData._id,
          reason,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isUpdating: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].data_update_success
          );

          this.handleCloseModal(modal);
          this.getData();
        })
        .catch((error) => {
          this.setState({ isUpdating: false });
          toastMessage("error", error);
        });
    }
  };

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteBloodDonation(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData();
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData({
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    const { export_, add_collection } = language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          addSearchButton
          searching={this.state.searching}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_COLLECTIONS,
            operation: "create",
            pass:
              this.state.user.role === "donor" ||
              this.state.user.account_type === "donor",
          })}
          addButtonText={add_collection}
          handleAddPressed={() =>
            this.handleShowModal(
              this.props.donorId ? "showModal" : "searchDonorModal",
              add_collection
            )
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(),
            },
            {
              type: "dropdown",
              filterName: "date_range",
              title: language[this.props.defaultLanguage].date_range,
              button_type: "dropdown",
              handleApplyFilters: () => this.getData(),
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].view,
              onPress: (item) =>
                (window.location.href = `/dashboard/donations/${item._id}/${
                  item.donor._id
                }/${item.donor.firstName + " " + item.donor.lastName}`),
              icon: icons.eye_on,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].update,
              onPress: (item) =>
                this.handleShowModal(
                  "showModal",
                  language[this.props.defaultLanguage].update,
                  item
                ),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_BLOOD_TEST,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].blood_test,
              onPress: (item) =>
                (window.location.href = `/dashboard/donations/${item._id}/${item.donor._id}/${item.donor.firstName} ${item.donor.lastName}`),
              icon: icons.blood_test,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].suspend,
              onPress: this.handleShowModal.bind(
                this,
                "showSuspendModal",
                language[this.props.defaultLanguage].suspend
              ),
              icon: icons.close,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].block,
              onPress: this.handleShowModal.bind(
                this,
                "showBlockModal",
                language[this.props.defaultLanguage].block
              ),
              icon: icons.block,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COLLECTIONS,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].block_permanent
              ),
              icon: icons.delete,
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
          backdrop="static"
        >
          <NewCollection
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donorId={this.props.donorId}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "searchDonorModal")}
          show={this.state.searchDonorModal}
          title={language[this.props.defaultLanguage].search_donor}
        >
          <SearchDonor
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "searchDonorModal"
            )}
            actionMenuSize={12}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showVitalSignModal")}
          show={this.state.showVitalSignModal}
          title={this.state.modalTitle}
        >
          <ViewVitalSign
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showVitalSignModal"
            )}
            signId={this.state.selectedData?._id || this.state.selectedData}
            examQuestionnaire={
              this.state?.selectedData?.examQuestionnaire || []
            }
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSuspendModal")}
          show={this.state.showSuspendModal}
          title={language[this.props.defaultLanguage].suspend}
        >
          <ConfirmationModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSuspendModal"
            )}
            error={this.state.error}
            onPressNegative={this.handleCloseModal.bind(
              this,
              "showSuspendModal"
            )}
            onPressPositive={() =>
              this.onUpdateStatus("suspended", "showSuspendModal")
            }
            onChange={(e) => this.onChangeText("reason", e)}
            isSubmitting={this.state.isSubmitting}
            getData={this.getData.bind(this)}
            withReason
            reason={this.state.reason}
            reasonFieldName="reason"
            negativeButtonText={language[this.props.defaultLanguage].cancel}
            positiveButtonText={language[this.props.defaultLanguage].submit}
            isPositiveSubmitting={this.state.isUpdating}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showBlockModal")}
          show={this.state.showBlockModal}
          title={language[this.props.defaultLanguage].block}
        >
          <ConfirmationModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showBlockModal"
            )}
            error={this.state.error}
            onPressNegative={this.handleCloseModal.bind(
              this,
              "showSuspendModal"
            )}
            onPressPositive={() =>
              this.onUpdateStatus("blocked", "showBlockModal")
            }
            onChange={(e) => this.onChangeText("reason", e)}
            isSubmitting={this.state.isSubmitting}
            getData={this.getData.bind(this)}
            withReason
            reason={this.state.reason}
            reasonFieldName="reason"
            negativeButtonText={language[this.props.defaultLanguage].cancel}
            positiveButtonText={language[this.props.defaultLanguage].submit}
            isPositiveSubmitting={this.state.isUpdating}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodCollection);
