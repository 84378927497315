import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import language from "../../language";
import { Input } from "../Input";
import { Button } from "../Button";
import { clearStorage, setStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import calculateAge from "../../utils/calculateAge";
class DateBirthChecker extends React.Component {
  state = {
    dateOfBirth: null,
    error: {},
  };

  componentDidMount = async () => {
    await clearStorage("pre-assessment");
  };

  onChangeInput = async (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e?.target?.value });
  };

  validateForm() {
    let { error, dateOfBirth } = this.state;
    const { defaultLanguage } = this.props;
    const age = calculateAge(dateOfBirth);

    if (!dateOfBirth) {
      error.dateOfBirth = language[defaultLanguage].date_birth_required;
    } else if (age < 18) {
      error.dateOfBirth = language[defaultLanguage].below_year_old;
    } else if (age > 60) {
      error.dateOfBirth = language[defaultLanguage].above_year_old;
    }

    this.setState({ error });
  }

  onEnter = async () => {
    await this.validateForm();

    const { error, dateOfBirth } = this.state;

    if (Object.keys(error).length === 0) {
      await setStorage({ dateOfBirth }, "pre-assessment");

      this.props.handleCloseModal();
    }
  };

  onCancel() {
    this.props.handleCloseModal();

    window.history.go(-1);
    return false;
  }

  render() {
    return (
      <div>
        <div>
          <div className="modal-body">
            <center>
              <div className="date-checker-icon-container primary">
                <span className={process.env.REACT_APP_ICONS_TYPE}>
                  {icons.calendar}
                </span>
              </div>
              <h2>
                {
                  language[this.props.defaultLanguage]
                    .date_birth_date_checker_title
                }
              </h2>
              <br />
              <p className="text-disabled">
                {
                  language[this.props.defaultLanguage]
                    .date_birth_date_checker_description
                }
              </p>
              <br />
            </center>

            <Input
              label={language[this.props.defaultLanguage].birth_date}
              type="date"
              value={this.state.dateOfBirth}
              onChange={(e) => this.onChangeInput("dateOfBirth", e)}
              error={this.state.error.dateOfBirth}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].cancel}
              onPress={this.onCancel.bind(this)}
            />
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onEnter.bind(this)}
              isSubmitting={this.state.isSubmitting}
              disabled={
                !this.state.dateOfBirth ||
                Object.keys(this.state.error).length > 0
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DateBirthChecker);
