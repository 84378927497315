import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import departmentQuery from "../../utils/queries/departmentQuery";
import jobPositionQuery from "../../utils/queries/jobPositionQuery";
import accessRoleQuery from "../../utils/queries/accessRoleQuery";
import validateEmail from "../../utils/validateEmail";
import { activeOptions } from "../../constants/account";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import Separator from "../Separator/Separator";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import hospitalQuery from "../../utils/queries/hospitalQuery";
import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";
import { defineRole } from "../../utils/handleAccessRoles";
import { ROLE_ACCOUNT } from "../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewAccount extends React.Component {
  state = {
    email: "",
    username: "",
    error: {},
    isSubmitting: false,
    departments: [],
    accessRoles: [],
    jobPositions: [],
    roles: [],
    status: activeOptions[0],
    showAccessRoles: false,
    password: "",
    name: "",
    pin: "",
    user: {},
    hospitals: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    this.getDepartments(true);
    this.getRoles(true);
    this.getCenters(true);
    this.getHospitals(true);

    if (this.props._id) {
      if (this.props.center) {
        this.getCenterSites(
          true,
          this.props.center.map((el) => el._id)
        );
      }
      for (let status of activeOptions) {
        if (status.available === this.props.active) {
          this.setState({
            status,
          });
        }
      }

      let accessRole = this.props.accessRole?.map((el) => {
        return {
          label: el.name,
          value: el._id,
        };
      });

      this.setState({
        ...this.props,
        status:
          activeOptions.find((el) => el.value === this.props.available + "") ||
          activeOptions[0],
        center: this.props.center
          ? {
              label: this.props.center.name,
              value: this.props.center._id,
            }
          : null,
        centerSite: this.props.centerSite
          ? {
              label: this.props.centerSite.name,
              value: this.props.centerSite._id,
            }
          : null,

        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,

        accessRole,
      });
    }
  };

  getCenters = async (isFetchingCenter) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      let selectedCenter = null;

      if (this.props.center) {
        data.map((el) => {
          if (!selectedCenter) {
            selectedCenter = [];
          }

          this.props.center.forEach((hEl) => {
            if (hEl._id === el._id) {
              selectedCenter.push({
                label: el.name,
                value: el._id,
              });
            }
          });
        });
      }

      this.setState({
        centers: data,
        isFetchingCenter: false,
        center: selectedCenter,
      });
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  getCenterSites = async (isFetchingCenterSite, center) => {
    try {
      this.setState({
        isFetchingCenterSite,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center,
      });

      let selectedCenterSite = null;

      if (this.props.centerSite) {
        data.map((el) => {
          if (!selectedCenterSite) {
            selectedCenterSite = [];
          }

          this.props.centerSite.forEach((hEl) => {
            if (hEl._id === el._id) {
              selectedCenterSite.push({
                label: el.name,
                value: el._id,
              });
            }
          });
        });
      }

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
        centerSite: selectedCenterSite,
      });
    } catch (error) {
      this.setState({ isFetchingCenterSite: false });
    }
  };

  getHospitals = async (isFetchingHospital) => {
    try {
      this.setState({
        isFetchingHospital,
      });

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      let hospital = null;

      if (this.props.hospital) {
        data.map((el) => {
          if (!hospital) {
            hospital = [];
          }

          this.props.hospital.forEach((hEl) => {
            if (hEl._id === el._id) {
              hospital.push({
                label: el.name,
                value: el._id,
              });
            }
          });
        });
      }

      this.setState({
        hospitals: data,
        isFetchingHospital: false,
        hospital,
      });
    } catch (error) {
      this.setState({ isFetchingHospital: false });
    }
  };

  getDepartments = async (isFetchingDepartment, departmentName) => {
    try {
      this.setState({
        isFetchingDepartment,
      });

      const data = await departmentQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedDepartment(data);

      this.setState({
        departments: data,
        isFetchingDepartment: false,
      });
    } catch (error) {
      this.setState({ isFetchingDepartment: false });
    }
  };

  getJobPositions = async (isFetchingPosition, department) => {
    try {
      this.setState({
        isFetchingPosition,
      });

      const data = await jobPositionQuery(this.props.defaultLanguage, {
        department,
        type: "dropdown",
      });

      this.setSelectedPosition(data, this.props.position);

      this.setState({
        jobPositions: data,
        isFetchingPosition: false,
      });
    } catch (error) {
      this.setState({ isFetchingPosition: false });
    }
  };

  getRoles = async (isFetchingAccessRole) => {
    try {
      this.setState({
        isFetchingAccessRole,
      });

      const data = await accessRoleQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        accessRoles: data,
        isFetchingAccessRole: false,
      });
    } catch (error) {
      this.setState({ isFetchingAccessRole: false });
    }
  };

  setSelectedDepartment(departments) {
    const { department } = this.props;
    if (department) {
      const selectedDepartment = departments.find(
        (el) =>
          el._id === department._id ||
          el._id === department ||
          el.label === department
      );

      if (selectedDepartment?._id) {
        this.setState(
          {
            department: {
              label: selectedDepartment.name,
              value: selectedDepartment._id,
            },
          },
          () => {
            this.getJobPositions(true, selectedDepartment._id);
          }
        );
      }
    }
  }

  setSelectedPosition(positions, position) {
    if (position) {
      const selectedPosition = positions.find(
        (el) =>
          el._id === position._id ||
          el._id === position ||
          el.label === position
      );

      if (selectedPosition?._id) {
        this.setState({
          jobPosition: {
            label: selectedPosition.name,
            value: selectedPosition._id,
          },
        });
      }
    }
  }

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "department") {
      this.setState({ jobPosition: null });
      this.getJobPositions(true, inputValue.value);
    }

    if (field === "center") {
      this.getCenterSites(
        true,
        inputValue.map((el) => el.value)
      );
    }
  };

  validateForm() {
    let {
      username,
      email,
      department,
      jobPosition,
      accessRole,
      error,
      name,
      pin,
    } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    if (pin === "") {
      error.pin = language[this.props.defaultLanguage].pin_required;
    }

    if (username === "") {
      error.username = language[this.props.defaultLanguage].username_required;
    }

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email;
    }

    if (!department) {
      error.department =
        language[this.props.defaultLanguage].department_required;
    }

    if (!jobPosition) {
      error.jobPosition =
        language[this.props.defaultLanguage].job_position_required;
    }

    if (!accessRole || accessRole.length === 0) {
      error.accessRole =
        language[this.props.defaultLanguage].access_role_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          username,
          email,
          department,
          jobPosition,
          accessRole,
          _id,
          status,
          password,
          name,
          pin,
          center,
          centerSite,
          hospital,
        } = this.state,
        url = `${API_URL}/account`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        email,
        accessRole: accessRole.map((el) => el.value),
        department: department.value,
        username,
        name,
        position: jobPosition.value,
        active: status.available,
        password,
        employeeId: new Date().getTime(), //mifotra id
        pin: pin,
        passcode: pin,
        hospital: hospital?.map((el) => el.value) || undefined,
        center: center?.map((el) => el.value) || undefined,
        centerSite: centerSite?.map((el) => el.value) || undefined,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      } else {
        requestedBody.firstTime = true;
      }
      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_account_success"
                : "add_account_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    console.log({ accessRole: this.state.accessRole });
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].username_placeholder
                }
                label={language[this.props.defaultLanguage].username}
                required
                value={this.state.username}
                onChange={(e) => this.onChangeText("username", e)}
                error={this.state.error.username}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.email}
                placeholder={
                  language[this.props.defaultLanguage].email_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>
            {!this.props._id && (
              <>
                <div className="col-md-6">
                  <Input
                    leftIcon={icons.password}
                    placeholder={
                      language[this.props.defaultLanguage].password_placeholder
                    }
                    label={language[this.props.defaultLanguage].password}
                    required
                    value={this.state.password}
                    onChange={(e) => this.onChangeText("password", e)}
                    error={this.state.error.password}
                    type="password"
                  />
                </div>
              </>
            )}
            <div className="col-md-12">
              <Input
                leftIcon={icons.pin}
                placeholder={
                  language[this.props.defaultLanguage].pin_placeholder
                }
                label={language[this.props.defaultLanguage].pin}
                required
                value={this.state.pin}
                onChange={(e) => this.onChangeText("pin", e)}
                error={this.state.error.pin}
                inputStyles={{ textTransform: "uppercase" }}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.departments}
                leftIcon={icons.department}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].department}
                required
                value={this.state.department}
                onChange={(e) => this.onChangeText("department", e)}
                error={this.state.error.department}
                isLoading={this.state.isFetchingDepartment}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.jobPositions}
                leftIcon={icons.position}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].job_position}
                required
                value={this.state.jobPosition}
                onChange={(e) => this.onChangeText("jobPosition", e)}
                error={this.state.error.jobPosition}
                isLoading={this.state.isFetchingPosition}
              />
            </div>
            <div className="col-md-12">
              <Select
                leftIcon={icons.position}
                options={this.state.accessRoles}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].access_role}
                required
                value={this.state.accessRole}
                onChange={(e) => this.onChangeText("accessRole", e)}
                error={this.state.error.accessRole}
                isLoading={this.state.isFetchingAccessRole}
                isMulti
              />
            </div>
          </div>
          <Separator
            title={
              language[this.props.defaultLanguage].only_for_center_site_account
            }
          />
          <div className="row">
            <div className="col-md-12">
              <Select
                options={this.state.centers}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenter}
                isMulti
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.centerSites}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].site}
                value={this.state.centerSite}
                onChange={(e) => this.onChangeText("centerSite", e)}
                error={this.state.error.centerSite}
                isLoading={this.state.isFetchingSite}
                isMulti
              />
            </div>
            <hr />
            <Separator
              title={
                language[this.props.defaultLanguage]
                  .only_entity_or_hospital_account
              }
            />
            <div className="row">
              <div className="col-md-12">
                <Select
                  options={this.state.hospitals}
                  placeholder={language[this.props.defaultLanguage].select}
                  value={this.state.hospital}
                  onChange={(e) => this.onChangeText("hospital", e)}
                  error={this.state.error.hospital}
                  isLoading={this.state.isFetchingHospital}
                  isMulti
                />
              </div>
            </div>
            <hr />
            <div className="col-md-12">
              <Select
                leftIcon={icons.status}
                options={activeOptions}
                label={language[this.props.defaultLanguage].status}
                placeholder={language[this.props.defaultLanguage].select}
                required
                value={this.state.status}
                onChange={(e) => this.onChangeText("status", e)}
                error={this.state.error.status}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          {defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_ACCOUNT,
            operation: "create,update",
          }) && (
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewAccount);
