import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { fetchComponentsSeparationProfiles } from "../../../utils/queries/production/profiles/separation";
import formatSelectData from "../../../utils/formatSelectData";
import Separations from "./Separations";
import { CardHeader } from "../../Header";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class BulkDonation extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    donation: {},
    isLoading: true,
    available: availableOptions[0],
    profiles: [],
    bagStatuses: [],
    volume: "",
    donationNumber: "",
    donations: [],
    components: [],
    printLabelEnabled: false,
    separatedData: [],
    selectedData: {},
    donationNumbers: [],
  };

  getDonation = async (searchDonation) => {
    try {
      if (this.state.donationNumber === "") {
        let { error } = this.state;

        error.donationNumber =
          language[this.props.defaultLanguage].donation_number_required;

        this.setState({ error });

        return;
      }
      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
      });

      let { donationNumbers } = this.state;

      let donation = {};

      if (data.length > 0) {
        donation = data[0];
        donationNumbers.push(data[0].donationNumber);
      } else {
        toastMessage("error", language[this.props.defaultLanguage].no_data);
      }

      this.setState({
        donation,
        searchDonation: false,
        donationNumbers,
      });
    } catch (error) {
      this.setState({ searchDonation: false });
    }
  };

  getComponentProfiles = async (isFetchingProfile, code) => {
    try {
      this.setState({
        isFetchingProfile,
      });

      const data = await fetchComponentsSeparationProfiles(
        this.props.defaultLanguage,
        {
          type: "separation",
          inputType: "dropdown",
          code,
        }
      );

      this.setState({
        isFetchingProfile: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingProfile: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error, donation } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];
    delete error["errorMessage"];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "profile") {
      this.setState({
        components: formatSelectData(
          this.state?.profile?.components,
          "name",
          "_id"
        ),
        component: null,
        donation: {},
      });
    }

    if (field === "component") {
      this.setState({
        volume: inputValue.volume,
      });
    }
  };

  handleChangeComponent = (idex, field, e) => {
    let { profile = {} } = this.state;
    let { components = [] } = profile;

    components[idex][field] = e.target ? e?.target?.value : e;
    this.setState({ profile });
  };

  validateForm() {
    let { profile, component, error, volume } = this.state;

    if (!profile) {
      error.profile =
        language[this.props.defaultLanguage].component_profile_required;
    }

    if (!component) {
      error.component = language[this.props.defaultLanguage].component_required;
    }

    if (!volume === "volume" || volume === "") {
      error.volume = language[this.props.defaultLanguage].volume_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      const { donation, component } = this.state;
      this.setState({
        isSubmitting: true,
      });

      const savedData = await this.handleSeparationData(donation);

      this.setState({
        isSubmitting: false,
      });
    }
  };

  handleSeparationData = async (donation) => {
    let { _id } = this.props;
    let { profile, available, component, volume } = this.state,
      url = `${API_URL}/bloodSeparation`,
      method = "POST",
      user = await getStorage();

    this.setState({
      isSubmitting: true,
      isRefreshing: false,
    });

    let requestedBody = {
      donation: donation._id,
      components: [
        {
          _id: component._id,
          name: component.name,
          code: component.code,
          volume,
          ...component,
          addedBy: user.id,
        },
      ],

      available: available.value,
      profile: profile._id,
    };

    if (method === "PUT") {
      requestedBody.modifiedBy = user.id;
    } else {
      requestedBody.addedBy = user.id;
    }

    if (_id && _id !== "") {
      method = "PUT";
      requestedBody.id = _id;

      delete requestedBody.addedBy;
    }

    const options = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };
    return axios(options)
      .then(async (data) => {
        toastMessage(
          "success",
          language[this.props.defaultLanguage][
            method === "PUT" ? "update_data_success" : "add_data_success"
          ]
        );

        this.setState({
          isSubmitting: false,
          isRefreshing: true,
          donationNumber: "",
          donation: null,
        });

        return data;
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", error);

        this.onReset();

        return error;
      });
  };

  onReset = () => {
    this.setState({
      donation: null,
      donationNumbers: [],
    });
  };

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleOpenModal(modal, productId) {
    this.setState({
      [modal]: true,
      productId,
    });
  }

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <>
        <div
          className="card"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <div className="card-body">
            <Select
              loadOptions={(v) => this.getComponentProfiles(true, v)}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].component_profiles}
              required
              value={this.state.profile}
              onChange={(e) => this.onChangeText("profile", e)}
              error={this.state.error.profile}
              isLoading={this.state.isFetchingProfile}
            />

            {this.state.components?.length > 0 && (
              <Select
                options={this.state.components}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].component}
                required
                value={this.state.component}
                onChange={(e) => this.onChangeText("component", e)}
                error={this.state.error.component}
              />
            )}

            <Input
              placeholder={language[this.props.defaultLanguage].search + "..."}
              label={language[this.props.defaultLanguage].donation_number}
              required
              value={this.state.donationNumber}
              error={this.state.error.donationNumber}
              onChange={(e) => this.onChangeText("donationNumber", e)}
            />

            {this.state.donation?._id ? (
              <>
                {this.state.component?.value && (
                  <Input
                    placeholder={language[this.props.defaultLanguage].volume}
                    label={language[this.props.defaultLanguage].volume}
                    required
                    value={this.state.volume}
                    error={this.state.error.volume}
                    onChange={(e) => this.onChangeText("volume", e)}
                    type="number"
                    min={1}
                  />
                )}
                <hr />
                <div style={{ marginBottom: "1rem" }} />
                <div className="d-flex gap-2">
                  <Button
                    text={language[this.props.defaultLanguage].reset}
                    onPress={() => this.onReset()}
                    className="btn-default"
                  />
                  <Button
                    text={language[this.props.defaultLanguage].submit}
                    onPress={() => this.onSubmit()}
                    isSubmitting={this.state.isSubmitting}
                  />
                </div>
              </>
            ) : (
              <Button
                text={language[this.props.defaultLanguage].search}
                onPress={() => this.getDonation(true)}
                isSubmitting={this.state.searchDonation}
              />
            )}
          </div>
        </div>

        {this.state?.component && (
          <div className="card" style={{ marginTop: "1rem" }}>
            <CardHeader
              title={language[this.props.defaultLanguage].component_separation}
            />
            <div className="card-body">
              <Separations
                donationId={this.state?.donation?._id}
                component={this.state?.component?.value}
                componentProfile={this.state?.profile?.value}
                isRefreshing={this.state.isRefreshing}
                type="separation"
                queryType="bulk"
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BulkDonation);
