//This function handles roles/permission and access of the sidebar admin menus
//Super admin will access all

import { getStorage } from "./storage";

//Normal admin access will be determined by the role admin has granted you
export const defineRole = ({ roles = [], menu, operation, pass = false }) => {
  try {
    const userLoggedIn = JSON.parse(
      sessionStorage.getItem(process.env.REACT_APP_NAME) || "{}"
    );

    const { role = "" } = userLoggedIn;

    let accessedMenus = [];

    roles.forEach((roleObj) => {
      const { permissions } = roleObj;
      const { menus, operations } = permissions;

      // Support multiple operations check
      let splitOperation = operation.split(",");

      splitOperation.forEach((op) => {
        if (operations[op]) {
          if (Array.isArray(menu)) {
            menu.forEach((mEl) => {
              if (operations[op]?.includes(mEl) && menus?.includes(mEl)) {
                accessedMenus.push(mEl);
              }
            });
          } else if (operations[op]?.includes(menu) && menus?.includes(menu)) {
            accessedMenus.push(menu);
          }
        }
      });
    });

    if (role === "superAdmin" || pass) {
      return true;
    } else if (Array.isArray(menu)) {
      return menu.find((mEl) => accessedMenus.includes(mEl));
    } else {
      return accessedMenus.includes(menu);
    }
  } catch (error) {
    return false;
  }
};

export const defineRoleCenterSite = async () => {
  const { center, centerSite, role, hospital } = await getStorage();

  if (role === "superAdmin") {
    return {};
  }

  return {
    centerRole: center ? center : undefined,
    centerSiteRole: centerSite ? centerSite : undefined,
    hospitalRole: hospital ? hospital : undefined,
  };
};
