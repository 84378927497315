import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import {
  getCells,
  getDistricts,
  getProvinces,
  getSectors,
  getVillages,
} from "../../utils/rwanda";
import { hospitals } from "../../constants/categories";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class NewHospital extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    type: hospitals(this.props.defaultLanguage)[0],
  };

  componentDidMount() {
    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        province: {
          label: this.props?.address?.province,
          value: this.props?.address?.province,
        },
        district: {
          label: this.props?.address?.district,
          value: this.props?.address?.district,
        },
        sector: {
          label: this.props?.address?.sector,
          value: this.props?.address?.sector,
        },
        cell: {
          label: this.props?.address?.cell,
          value: this.props?.address?.cell,
        },
        village: {
          label: this.props?.address?.village,
          value: this.props?.address?.village,
        },
        type: {
          label: this.props?.type,
          value: this.props?.type,
        },
      });
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  validateForm() {
    let { name, error, province, sector, cell, district, village } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].hospital_name_required;
    }

    if (!province) {
      error.province = language[this.props.defaultLanguage].province_required;
    }

    if (!district) {
      error.district = language[this.props.defaultLanguage].district_required;
    }

    if (!sector) {
      error.sector = language[this.props.defaultLanguage].sector_required;
    }

    if (!village) {
      error.village = language[this.props.defaultLanguage].village_required;
    }

    if (!cell) {
      error.cell = language[this.props.defaultLanguage].cell_required;
    }

    if (!province) {
      error.province = language[this.props.defaultLanguage].province_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_hospital,
      error_update_hospital,
      add_hospital_success,
      update_hospital_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, _id, province, district, sector, village, cell, type } =
          this.state,
        url = `${API_URL}/hospital`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        address: {
          province: province.value,
          district: district.value,
          sector: sector.value,
          cell: cell.value,
          village: village.value,
        },
        type: type.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT" ? update_hospital_success : add_hospital_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_hospital : error_add_hospital
          );

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.hospitals}
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getProvinces(this.state.province)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].province}
                required
                value={this.state.province}
                onChange={(e) => this.onChangeText("province", e)}
                error={this.state.error.province}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getDistricts(this.state.province?.value)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].district}
                required
                value={this.state.district}
                onChange={(e) => this.onChangeText("district", e)}
                error={this.state.error.district}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getSectors(
                  this.state.province?.value,
                  this.state.district?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].sector}
                required
                value={this.state.sector}
                onChange={(e) => this.onChangeText("sector", e)}
                error={this.state.error.sector}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getCells(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].cell}
                required
                value={this.state.cell}
                onChange={(e) => this.onChangeText("cell", e)}
                error={this.state.error.cell}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getVillages(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value,
                  this.state.cell?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].village}
                required
                value={this.state.village}
                onChange={(e) => this.onChangeText("village", e)}
                error={this.state.error.village}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={hospitals(this.props.defaultLanguage) || []}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].type}
                required
                value={this.state.type}
                onChange={(e) => this.onChangeText("type", e)}
                error={this.state.error.type}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewHospital);
